import commonHelper from '@/app/utility/common.helper.utility';
import AddgstMaster from './addgstMaster'
export default {
    name: '',
    watch: {
        currentPage: function() {
          this.getgstMasterGridViewList();
        },
        perPage: function() {
          this.currentPage = 1;
          this.getgstMasterGridViewList();
        }
      },
    components: {
        AddgstMaster
    },
    props: {},
    data() {
         return{
            payload: null,
            unsubscribe: null,
            currentPage: 1,
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            loader: false,
            masterType: null,
            gstMasterRowDetail: null,
            showAddGstMasterModal: false,
            active: true,
            gstMasterdata: [],
            fields: [
                {
                    key: 'master_type',
                    label: 'Master Type'
                },
                {
                    key: 'gst_master_id',
                    class: 'd-none'
                },
                {
                    key: 'master_code',
                    label: 'Master Code'
                },
                {
                    key: 'master_desc',
                    label: 'Master Description'
                },
                {
                    key: 'enabled',
                    label: 'Active'
                }
            ],
         };
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
              const actionName = state.shared.actionName;
              if (actionName === 'add') {
                this.showAddGstMasterModal = true;
                this.gstMasterRowDetail = null;
              }
              if (actionName === 'download' && !this.showAddGstMasterModal) {
                this.loader = true;
                /**
                 * @param(payload, 'action name', 'file name')
                 */
                const downloadpayload = { ...this.payload };
                downloadpayload._limit = this.totalRows;
                this.downloadExcel.downloadData(
                  downloadpayload,
                  'gstMaster/getGstMasterList',
                  'gst-master',
                  () => (this.loader = false)
                );
              }
            }
          });
    },
    methods: {
        getgstMasterGridViewList() {
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
            };
      this.loader = true;
      this.$store
        .dispatch('gstMaster/getGstMasterList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gstMasterdata = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
        },
        hideChildModal(){},
        clear() {
            this.masterType = null;
            this.gstMasterdata = [];
            this.totalRows = null;
            this.currentPage = 1;
        },
        rowSelected(item) {
            this.gstMasterRowDetail = item;
            this.showHideGstMasterModal(true);
        },
        showHideGstMasterModal(flag) {
           this.showAddGstMasterModal = flag;
        },
    },
    beforeDestroy() {
    this.unsubscribe();
    }
    };