import appStrings from '@/app/utility/string.utility';
export default {
    name: 'addgstMaster',
    components: {},
    props: ['gstMasterRowDetail'],
    data() {
        return {
            loader: false, 
            unsubscribe: null,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            editMode: false,
            gstMasterId: 0,
            masterType: null,
            masterCode: null,
            masterDescription: null,
            active: false
        }
    },
    mounted() {
        if (this.gstMasterRowDetail) {
            this.fillRecordFromgstMasterParent(this.gstMasterRowDetail);
          } else {
            this.editMode = true;
          }
            this.unsubscribe = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'shared/setActionName') {
                  const actionName = state.shared.actionName;
                  if (actionName === 'save' && this.editMode) {
                    this.addEditgstMaster();
                  }
                  if (actionName === 'update') {
                    this.editMode = true;
                  }
                }
              });
    },
    methods: {
        fillRecordFromgstMasterParent(item) {
          this.masterType = item.master_type;
          this.masterCode = item.master_code;
          this.masterDescription = item.master_desc;
          this.active = item.enabled==="Y"?true:false;
          this.gstMasterId = item.gst_master_id;
        },
        addEditgstMaster() {
            const payload ={
                master_type: this.masterType,
                master_code: this.masterCode, 
                master_desc: this.masterDescription,
                active: this.active?"Y":"N",
                gst_master_id: this.gstMasterId
              };
              this.loader = true;
      this.$store
        .dispatch('gstMaster/addEditgstMaster', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
        },

    },
    beforeDestroy() {
    this.unsubscribe();
    }
    };